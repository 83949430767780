import file from './uploadTemplate.csv';

(function() {
    angular.module('EntrakV5').controller('accountController', accountController);

    function accountController($scope, $rootScope, Service, $q, $timeout, Api, KEY, APIKEY) {
        console.log('accountController');

        var caller = Api.createApiCaller();
        $rootScope.title = Service.translate("account.title");

        $scope.INVITE_STATUS = APIKEY.invite;
        $scope.userList = null;
        $scope.departments = null;
        $scope.floorMap = {};
        $scope.zoneAdminAssignments = {};

        $scope.deptMap = {};

        $scope.tenantId = null;

        $scope.loadingMore = false;
        $scope.allLoaded = null;
        $scope.initiatingTable = false;

        var offset = null;
        var limit = 25;
        var resultFor = null;

        //first load
        $scope.loadUserList = function(searchText){
            $rootScope.loadingPage++;
            $scope.initiatingTable = true;
            $scope.userList = [];
            offset = 0;
            $scope.allLoaded = false;
            resultFor = searchText;
            var txt = searchText ? searchText : undefined;
            $rootScope.getTenantId().then(function(tenantId){
              caller.call(Api.getProfiles(tenantId, 2, limit, offset, txt)).then(function(res){
                  $rootScope.loadingPage--;
                  $scope.userList = res;
                  res.forEach(function(u){
                    if (!$scope.zoneAdminAssignments[u.id])
                      $scope.zoneAdminAssignments[u.id] = [];
                  });
                  offset += res.length;
                  if (res.length < limit)
                      $scope.allLoaded = true;
                  $scope.initiatingTable = false;
              }, function(err){
                  if (err === KEY.ignore)
                      return;
                  $scope.initiatingTable = false;
                  $rootScope.generalErrorHandler();
              });
            });
        }
        $scope.loadDepartments = function(tenantId) {
            $rootScope.loadingPage++;
            caller.call(Api.getDepartments(tenantId)).then(function(res){
                $rootScope.loadingPage--;
                $scope.departments = res;
                $scope.deptMap = Service.arrayToMap(res);
                $scope.updateDeptDropdown();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.generalErrorHandler();
            });
        }
        $scope.updateDeptDropdown = function(){
            $scope.departmentDropdown.setDataSource(new kendo.data.DataSource({
                data: $scope.departments
            }));
        }
        $rootScope.loadingPage = 2;
        $rootScope.getTenantId().then(function(tenantId){
            $scope.tenantId = tenantId;
            caller.call([Api.getAssignedZoneAdmins(), Api.getFloorList(tenantId)]).then(function(res){
                $rootScope.loadingPage--;
                $scope.isLongRole = res[0].length > 0;
                res[1].zones.sort(Service.getSorter());
                $scope.zoneDropdown.setDataSource(new kendo.data.DataSource({
                    data: res[1].zones
                }));
                $scope.floorMap = Service.arrayToMap(res[1].zones);
                res[0].forEach(function(assignment) {
                  if ($scope.zoneAdminAssignments[assignment.userId]) {
                    $scope.zoneAdminAssignments[assignment.userId].push(assignment.zoneId);
                  } else {
                    $scope.zoneAdminAssignments[assignment.userId] = [assignment.zoneId];
                  }
                });
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.generalErrorHandler();
            });
            $scope.loadDepartments(tenantId);
        });
        caller.call(Api.getSetRoleOptions()).then(function(res){
          $scope.roleDropdown.setDataSource(new kendo.data.DataSource({
            data: res.roles.map(function(r) {
              return {
                text: Service.translate("label." + r),
                value: r
              }
            })
          }));
          $rootScope.loadingPage--;
        }, function(err){
            if (err === KEY.ignore)
                return;
            $rootScope.generalErrorHandler();
        });

        $scope.loadMoreUsers = function(){
          $rootScope.loadingPage++;
          $scope.loadingMore = true;
          $timeout(function(){//without timeout, the loading screen will delay??
            var moreFor = $scope.searchText;
            caller.call(Api.getProfiles($scope.tenantId, 2, limit, offset, (moreFor ? moreFor : undefined))).then(function(res){
              $rootScope.loadingPage--;
              $scope.loadingMore = false;
              if (moreFor !== resultFor)
                return;
              res.forEach(function(u){
                if (!$scope.zoneAdminAssignments[u.id])
                  $scope.zoneAdminAssignments[u.id] = [];
              });
              $scope.userList = $scope.userList.concat(res);
              offset += res.length;
              if (res.length < limit)
                $scope.allLoaded = true;
            }, function(err){
              if (err === KEY.ignore)
                return;
              $scope.loadingMore = false;
              $rootScope.generalErrorHandler();
            });
          });
        }
        $scope.onScroll = function() {
          if (!$scope.allLoaded && !$scope.loadingMore && !$scope.initiatingTable) {
            var elm = $("#userTable").get(0);
            if (elm.scrollTop + elm.offsetHeight > elm.scrollHeight - 100)
              $scope.loadMoreUsers();
          }
        }

        $scope.searchText = "";
        $scope.lastSearchText = null;
        $scope.searchTimer = null;
        $scope.$watch("searchText", function(n) {
          $timeout.cancel($scope.searchTimer);
          $scope.searchTimer = $timeout(function(){
            console.log("search:", n);
            if ($scope.lastSearchText != n) {
              $scope.lastSearchText = n;
              $scope.loadUserList(n);
            }
          }, 400);
        });

        $scope.getRoleLabel = function(user){
            return 'label.' + user.aclRole;
        }

        $scope.noWorkstationTooltipOpt = {
            width: 200,
            callout: false,
            position: "right",
            filter: ".noWorkstationIcon",
            content: Service.translate("account.noWorkstation")
        }

        $scope.departmentDropdownOpt = {
            autoWidth: true,
            dataTextField: "name",
            dataValueField: "id",
            optionLabel: Service.translate("label.noDept")
        }

        $scope.showAddAccountWin = function(user){
            $scope.hasCreateAccErr = false;
            $scope.hasUpdateAccErr = false;
            $scope.hasInvalidEmailErr = false;
            $scope.popupForEdit = user != null;
            $scope.editData = { saving: false };
            $scope.addAccountWin.title(Service.translate("account.addAccountWin." + ($scope.popupForEdit ? "titleEditAcc" : "titleAddAcc")));
            if ($scope.popupForEdit){
                $scope.editData.id = user.id;
                $scope.editData.email = user.email ? user.email.toLowerCase() : '';
                $scope.editData.fName = user.firstName;
                $scope.editData.lName = user.lastName;
                $scope.editData.department = user.departmentId;
                $scope.editData.role = user.aclRole;
                $scope.editData.cardNo = user.accessCard;
            } else {
                $scope.editData.role = APIKEY.role.user;
            }
            setTimeout(function(){
                $scope.addAccountWin.open().center();
            });
        }

        $scope.saveAccount = function(sendEmail){
            $scope.editData.saving = true;
            $scope.hasCreateAccErr = false;
            $scope.hasUpdateAccErr = false;
            $scope.hasInvalidEmailErr = false;
            $scope.hasInvalidNameErr = false;

            $scope.editData.cardNo = ($scope.editData.cardNo || "").trim();
            $scope.editData.email = ($scope.editData.email || "").trim().toLowerCase();
            if ($scope.popupForEdit){
                $scope.editData.fName = $scope.editData.fName.trim();
                $scope.editData.lName = $scope.editData.lName.trim();
                if ($scope.editData.fName){
                    var action = Api.updateProfile($scope.editData.id, $scope.editData.fName, $scope.editData.lName, $scope.editData.role, $scope.editData.department, $scope.editData.cardNo);
                } else {
                    $scope.hasInvalidNameErr = true;
                }
            } else if (Service.isEmail($scope.editData.email)){
                var action = Api.createProfile($scope.tenantId, $scope.editData.email, $scope.editData.role, $scope.editData.department, $scope.editData.cardNo, sendEmail);
            } else {
                $scope.hasInvalidEmailErr = true;
            }
            if ($scope.hasInvalidNameErr || $scope.hasInvalidEmailErr){
                $scope.editData.saving = false;
                return;
            }

            caller.call(action).then(function(res){
                Service.replaceArrItem($scope.userList, res, true);
                if (!$scope.zoneAdminAssignments[res.id])
                  $scope.zoneAdminAssignments[res.id] = [];
                $scope.addAccountWin.close();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                if ($scope.popupForEdit){
                    $scope.hasUpdateAccErr = true;
                } else {
                    $scope.hasCreateAccErr = true;
                }
                $scope.editData.saving = false;
            });
        }

        $scope.deleteAccountWin = function(){
            $scope.addAccountWin.close();
            $scope.editData.deleting = false;
            var id = $scope.editData.id;
            $rootScope.deletePopup.show("account.deleteUserTitle", "account.deleteUserDesc", $scope.editData.email, function(){
                $scope.editData.deleting = true;
                caller.call(Api.deleteProfile(id)).then(function(){
                    Service.deleteArrItem($scope.userList, id);
                    $rootScope.deletePopup.close();
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    $rootScope.showError(Service.translate("error.generalDelAccFail"));
                });
            });
        }

        $scope.toggleCheckAll = function(){
            $scope.userList.forEach(function(u){
                if (u.inviteStatusV2 != APIKEY.invite.active){
                    u.isChecked = !$scope.isAllChecked;
                } else {
                    u.isChecked = false;
                }
            });
        }

        $scope.inviteAllData = { users: [] };
        $scope.showInviteAllUserWin = function(){
            $scope.inviteAllData.invitingAll = false;
            $scope.inviteAllData.users = [];
            $scope.userList.forEach(function(u){
                if (u.isChecked)
                    $scope.inviteAllData.users.push(u);
            });
            setTimeout(function(){
                $scope.inviteAllWin.center().open();
            });
        }

        $scope.inviteAllUser = function(){
            $scope.inviteAllWin.close();
            $scope.inviteAllData.invitingAll = true;
            $q.all($scope.inviteAllData.users.map($scope.inviteUser)).then(function(){
                $scope.inviteAllData.invitingAll = false;
            });
        }

        $scope.inviteUser = function(user){
            user.inviting = true;
            return caller.call(Api.inviteUser(user.id)).then(function(res){
                user.inviteStatusV2 = res.inviteStatusV2;
                user.inviting = false;
                user.inviteSuccess = true;
                $timeout(function(){
                    delete user.inviteSuccess;
                }, 3000);
            }, function(err){
                if (err === KEY.ignore)
                    return;
                user.inviting = false;
                user.inviteSuccess = false;
            });
        }

        $scope.departmentData = {};
        $scope.departmentWinOpt = {
            title: Service.translate("button.manageDepartment"),
            width: "600px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
        }
        $scope.showDepartmentWin = function(){
            $scope.cancelEditDepartment();
            setTimeout(function(){
                $scope.departmentWin.center().open();
            });
        }
        $scope.editDepartment = function(dept){
            if (dept){
                $scope.departmentData.id = dept.id;
                $scope.departmentData.name = dept.name;
            }
        }
        $scope.addDepartment = function(){
            $scope.departmentData.id = false;    //false for new department
            $scope.departmentData.name = "";
            $("#departmentWin .cardScroller").animate({
                scrollTop: document.querySelector("#departmentWin .cardScroller").scrollHeight
            },"fast");
        }
        $scope.cancelEditDepartment = function(){
            $scope.departmentData = {};
        }
        $scope.saveDepartment = function(){
            $scope.departmentData.saving = true;
            if ($scope.departmentData.id){
                var action = Api.updateDepartment($scope.departmentData.id, $scope.departmentData.name);
            } else {
                var action = Api.createDepartment($scope.tenantId, $scope.departmentData.name);
            }
            caller.call(action).then(function(res){
                Service.replaceArrItem($scope.departments, res, true);
                $scope.updateDeptDropdown();
                $scope.cancelEditDepartment();
                $scope.departmentData.saving = false;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.departmentData.saving = false;
                $rootScope.showError(Service.translate("error.generalSaveFail"));
            });
        }
        $scope.deleteDepartment = function(dept){
            $rootScope.deletePopup.show("account.deleteDeptTitle", "account.deleteDeptDesc", dept.name, function(){
                caller.call(Api.deleteDepartment(dept.id)).then(function(res){
                    Service.deleteArrItem($scope.departments, dept);
                    $scope.updateDeptDropdown();
                    $rootScope.deletePopup.close();
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    $rootScope.showError(Service.translate("error.generalDelDeptFail"));
                });
            });
        }

        $scope.templateName = file;
        $scope.clickUpload = function(){
          $('#account #dataFileChooser').click();
        }
        $scope.onSelectFile = function(inputFile){
          if (!window.File || !window.FileReader){
            console.log("not supported");
            return;
          }

          if (!inputFile.files || !inputFile.files[0])
            return;

          var f = inputFile.files[0];
          // if (f.size > 10485760 || f.fileSize > 10485760){//10MB limit
          //   alert("uploadCtrl.max10Mb");
          //   return;
          // }

          $rootScope.loadingPage++;
          var fileReader = new FileReader();
          fileReader.onload = function(e){
            caller.call(Api.uploadAccounts($scope.tenantId, f)).then(function(h) {
              $scope.loadUserList($scope.lastSearchText);
              $scope.loadDepartments($scope.tenantId);
              $rootScope.loadingPage--;
            }, function(err) {
            if (err === KEY.ignore)
              return;
            $rootScope.loadingPage--;
            $rootScope.showError(Service.translate("error.generalSaveFail"));
          });
          }
          fileReader.readAsDataURL(f);

          inputFile.value = null;
        }


        $scope.inviteAllWinOpt = {
            title: Service.translate("account.inviteAllWin.title"),
            width: "650px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
        }

        $scope.addAccountWinOpt = {
            width: "800px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
        }

        $scope.roleDropdownOpt = {
            dataSource: [],
            dataTextField: "text",
            dataValueField: "value"
        }

        //zone admin
        $scope.assignZoneData = {};
        $scope.assignZoneWinOpt = {
            title: Service.translate("account.addZone"),
            width: "550px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            actions: ["Close"]
        }
        $scope.zoneDropdownOpt = {
            autoWidth: true,
            dataTextField: "name",
            dataValueField: "id",
        }

        $scope.showZoneListLbl = function(userId){
          if ($scope.zoneAdminAssignments[userId]){
            var arr = $scope.zoneAdminAssignments[userId].map(id => $scope.floorMap[id].name);
            arr.sort();
            return arr.join(', ');
          }
        }
        $scope.showAssignZoneWin = function(user){
            $scope.assignZoneData.id = user.id;
            $scope.assignZoneData.zones = $scope.zoneAdminAssignments[user.id];
            $scope.assignZoneData.showZoneInput = false;
            setTimeout(function(){
                $scope.assignZoneWin.center().open();
            });
        }
        $scope.showAddZoneInput = function(){
            $scope.assignZoneData.showZoneInput = true;
            $scope.assignZoneData.newZone = '';
            $("#assignZoneWin .cardScroller").animate({
                scrollTop: document.querySelector("#assignZoneWin .cardScroller").scrollHeight
            }, "fast");
        }
        $scope.addZone = function(){
            var tmp = $scope.assignZoneData;
            var zoneId = tmp.newZone;
            if (tmp.zones.indexOf(zoneId) > -1){
                tmp.showZoneInput = false;    
            } else {
                tmp.saving = true;
                caller.call(Api.assignZoneAdmin(tmp.id, zoneId)).then(function(res){
                    tmp.zones.push(zoneId);
                    tmp.showZoneInput = false;
                    tmp.saving = false;
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    tmp.saving = false;
                    $rootScope.showError(Service.translate("error.generalAssignZoneAdminFail"));
                })
            }
        }
        $scope.cancelAddZone = function(){
            $scope.assignZoneData.showZoneInput = false;
            $scope.assignZoneData.newZone = null;
        }
        $scope.removeZone = function(floorId){
            var tmp = $scope.assignZoneData;
            $rootScope.deletePopup.show("account.deleteZoneTitle", "account.deleteZoneDesc", $scope.floorMap[floorId].name, function(){
                caller.call(Api.unassignZoneAdmin(tmp.id, floorId)).then(function(res){
                    tmp.zones.splice(tmp.zones.indexOf(floorId), 1);
                    $rootScope.deletePopup.close();
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    $rootScope.showError(Service.translate("error.generalDelZoneFail"));
                });
            });
        }

        $scope.$on('$destroy', function() {
            console.log("accountController destroy");
            caller.cancel();
        });
    }
})();